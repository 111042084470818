import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import ItMonitoring from '../images/itMonitoring.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'
import PreciseAction from '../images/precisionAction.png'
import LibertyRing from '../animations/libertyRing.json'

var lottie

export default class BetterConversationIntelligence extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Conversation Intelligence</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Voice Analytics
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              Change The Trajectory Of A Conversation With Realtime Insights
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1/2, 1/3]} mb={["32px", 0]}>
              <CenteredImg src={PreciseAction} alt="Precise Action"/>
            </Box>
            <Box width={[1, 2/3]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              RECORDING-LESS, REALTIME TRANSCRIPTION
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
              By building AI and transcription capabilities directly into your phone system, Truly makes conversation analysis secure, improves rep performance in realtime, and makes just-in-time coaching possible.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid color='#292929'>
          <Section flexDirection="column" py={['3rem', '5rem']}>
            <Box width={[1/2, 2/3]} pb="16pt">
              <CenteredImg src={ItMonitoring} alt="IT Monitoring"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentSage} bold mb="1.5rem">
              LIVE KEYWORD SPOTTING
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
              Create advanced keyword spotting rules and get real-time notifications to identify the most important conversations happening in your organization.
              </Large>
              <Large as="p" color={colors.white} mb="1.5rem">
              Join live calls from anywhere using Truly's Listen, Whisper and Barge features.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1/2, 1/2]} pb="2rem">
              <CenteredImg src={TeamDashHalf} alt="Team Dashboard"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              CALL COACHING
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Find the conversations that matter with Truly's call search feature, or leverage our integrations with other Conversation Intelligence platforms like Execvision, Gong.io, Chorus.ai and VoiceOps.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
